<template>
  <div></div>
</template>

<script>
import { LOGOUT } from '@/store/auth/action'

import { createNamespacedHelpers } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
const authStore = createNamespacedHelpers('authStore')

export default {
  components: {},
  created() {
    this.logout().then(res => {
      this.showToast('Logout Success', 'top-center')
    })
  },
  data() {
    return {
    }
  },
  methods: {
    ...authStore.mapActions({
      logout: LOGOUT,
    }),
    showToast(title, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          variant: 'primary',
        },
      },
      {
        position,
      })
    },
  },
}
</script>
